import React from 'react';
import styled from 'styled-components';

import { LocaleDropdownList } from 'components/Navbar/DropdownNavLists';
import { Col, Container, Row } from 'components/Layout/Grid';

import {
  JAVA_HOVER,
  FONT_SIZE_DOCS_H2,
  JAVA_GREEN,
} from 'helper/constants/styles';
import strings from 'helper/localization/localization.js';

const LocaleContainer = styled(Container)`
  padding: 8vh 0 30px 0;
`;

const LocaleRow = styled(Row)`
  margin: 0 2rem;
`;

const LocaleCol = styled(Col)`
  display: flex;
  flex-direction: column;
  width: 100%;

  > h2 {
    font-weight: 700;
    margin-bottom: 2rem;
  }

  > h1 {
    color: ${JAVA_GREEN};

    &:hover {
      color: ${JAVA_HOVER};
    }
  }

  > a {
    font-size: ${FONT_SIZE_DOCS_H2};
    color: #ffffff;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #fff;

    &:hover {
      color: ${JAVA_GREEN};
    }
  }
`;

const LanguagePage = () => (
  <LocaleContainer>
    <LocaleRow>
      <LocaleCol>
        <h2 id="qa-select-lang-title">{strings.selectLanguage}</h2>
        <h1>{strings.helloWorld}</h1>
        <LocaleDropdownList />
      </LocaleCol>
    </LocaleRow>
  </LocaleContainer>
);

export default LanguagePage;
